const URLS = {
  /**
   * 发布到腾讯云
   */
  tencent: {
    IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/prod/",
    BASE_URL: "https://zt.nk.emergen.cn:6014",
    FACTORY_URL: "https://zt.nk.emergen.cn:6014",
    MACHINE_API: "https://test.emergen.cn/api/network",
    CDN_URL: "https://cdn.nk.emergen.cn",
    MES_IMG_URL: "https://cdn.nk.emergen.cn/prod/mat_picture/",
    MES_File_URL: "https://cdn.nk.emergen.cn/prod/bom_file/",
    PORTAL_URL: "https://zt.nk.emergen.cn:8943"
  },
  /**
   * 发布到腾讯云k8s
   */
  tencent_k8s: {
    IMG_URL_PRE: "https://zt.cdn.nk001.com/prod/",
    BASE_URL: "http://113.195.86.188:9700",
    FACTORY_URL: "http://113.195.86.188:9700",
    MACHINE_API: "https://test.emergen.cn/api/network",
    CDN_URL: "https://mes.cdn.nk001.com",
    MES_IMG_URL: "https://mes.cdn.nk001.com/prod/mat_picture/",
    MES_File_URL: "https://mes.cdn.nk001.com/prod/bom_file/",
    PORTAL_URL: "https://zt.nk.emergen.cn:8943"
  },
  /**
   * 发布到8006
   */
  test: {
    IMG_URL_PRE: "https://cdn.file.nk.emergen.cn/test/",
    BASE_URL: "https://consul.mes.emergen.cn:5004",
    FACTORY_URL: "https://consul.mes.emergen.cn:5004",
    MACHINE_API: "https://test.emergen.cn/api/network",
    CDN_URL: "https://cdn.nk.emergen.cn",
    MES_IMG_URL: "https://cdn.nk.emergen.cn/test/mat_picture/",
    MES_File_URL: "https://cdn.nk.emergen.cn/test/bom_file/",
    PORTAL_URL: "https://test.home.emergen.cn"
  },
  /**
   * 发布到8808
   */
  dev: {
    IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/",
    BASE_URL: "https://consul.mes.emergen.cn:6012",
    FACTORY_URL: "https://consul.mes.emergen.cn:6012",
    MACHINE_API: "http://61.174.171.61:1880",
    CDN_URL: "http://cdn.nk.emergen.cn",
    MES_IMG_URL: "http://cdn.nk.emergen.cn/dev/mat_picture/",
    MES_File_URL: "http://cdn.nk.emergen.cn/dev/bom_file/",
    PORTAL_URL: "http://demo.emergen.cn:17004"
  },
  development: {
    IMG_URL_PRE: "http://cdn.file.nk.emergen.cn/dev/",
    BASE_URL: "https://consul.mes.emergen.cn:6012",
    FACTORY_URL: "https://consul.mes.emergen.cn:6012",
    MACHINE_API: "http://61.174.171.61:1880",
    CDN_URL: "http://cdn.nk.emergen.cn",
    MES_IMG_URL: "http://cdn.nk.emergen.cn/dev/mat_picture/",
    MES_File_URL: "http://cdn.nk.emergen.cn/dev/bom_file/",
    PORTAL_URL: "http://demo.emergen.cn:17004",
    // PORTAL_URL: "http://localhost:3000"
  },
};
/**
 * 图片服务
 */
export const IMG_URL_PRE = URLS[process.env.VUE_APP_TITLE].IMG_URL_PRE;
export const BASE_URL = URLS[process.env.VUE_APP_TITLE].BASE_URL;
export const FACTORY_URL = URLS[process.env.VUE_APP_TITLE].FACTORY_URL;
export const MACHINE_API = URLS[process.env.VUE_APP_TITLE].MACHINE_API;
export const MES_IMG_URL = URLS[process.env.VUE_APP_TITLE].MES_IMG_URL;
export const MES_File_URL = URLS[process.env.VUE_APP_TITLE].MES_File_URL;
export const CDN_URL = URLS[process.env.VUE_APP_TITLE].CDN_URL;
export const PORTAL_URL = URLS[process.env.VUE_APP_TITLE].PORTAL_URL;
